import { FilePreviewModalComponent } from "./components/file-preview-modal/file-preview-modal.component";
import { ChatAreaComponent } from "./components/mailbox-folder-widget/chat-area/chat-area.component";
import { ExpectationIconWidgetComponent } from "./components/expectation-icon-widget/expectation-icon-widget.component";
import { StudentAttendanceComponent } from "src/app/shared/components/students-timeline/student-attendance/student-attendance.component";
import { StudentManagementComponent } from "./components/student-management/student-management.component";
import { StudentTimelineComponent } from "./components/students-timeline/student-timeline/student-timeline.component";
import { StudentProfileComponent } from "./components/student-profile/student-profile.component";
import { ViewResultsComponent } from "./components/students-timeline/student-surveys/view-results/view-results.component";
import { StudentAnswerComponent } from "./components/students-timeline/student-surveys/student-answer/student-answer.component";
import { StudentSurveysComponent } from "./components/students-timeline/student-surveys/student-surveys.component";
import { NewExpectationModalComponent } from "./components/new-expectation-modal/new-expectation-modal.component";
import { StudentExpectationsComponent } from "./components/students-timeline/student-expectations/student-expectations.component";
import { StudentCertificatesComponent } from "./components/students-timeline/student-certificates/student-certificates.component";
import { FilePickerModule } from "ngx-awesome-uploader";
import { CountdownWidgetComponent } from "./components/countdown-widget/countdown-widget.component";
import { EventsWidgetComponent } from "./components/events-widget/events-widget.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { ResourceCenterWidgetComponent } from "./components/resource-center-widget/resource-center-widget.component";
import { CalendarWidgetComponent } from "./components/calendar-widget/calendar-widget.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { NgxSelectModule } from "ngx-select-ex";

import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ImageCropperModule } from "ngx-img-cropper";

import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";

import { StudentDetailsWidgetComponent } from "./components/student-details-widget/student-details-widget.component";
import { ClockWidgetComponent } from "./components/clock-widget/clock-widget.component";
import { NotificationWidgetComponent } from "./components/notification-widget/notification-widget.component";
import { PopularWidgetComponent } from "./components/popular-widget/popular-widget.component";
import { AnnouncementWidgetComponent } from "./components/announcement-widget/announcement-widget.component";
import { NewsWidgetComponent } from "./components/news-widget/news-widget.component";
import { MailboxComposeWidgetComponent } from "./components/mailbox-compose-widget/mailbox-compose-widget.component";
import { MailboxMenuWidgetComponent } from "./components/mailbox-menu-widget/mailbox-menu-widget.component";
import { MailboxFolderWidgetComponent } from "./components/mailbox-folder-widget/mailbox-folder-widget.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ImportantAlertsWidgetComponent } from "./components/important-alerts-widget/important-alerts-widget.component";
import { TipsDayWidgetComponent } from "./components/tips-day-widget/tips-day-widget.component";
import { TimelineWidgetComponent } from "./components/timeline-widget/timeline-widget.component";
import { StudentSmallProfileWidgetComponent } from "./components/student-small-profile-widget/student-small-profile-widget.component";
import { StudentTeachersWidgetComponent } from "./components/student-teachers-widget/student-teachers-widget.component";
import { StudentFriendsWidgetComponent } from "./components/student-friends-widget/student-friends-widget.component";
import { StudentHeaderWidgetComponent } from "./components/student-header-widget/student-header-widget.component";
import { FaqSectionWidgetComponent } from "./components/faq-section-widget/faq-section-widget.component";
import { AnnouncementAdminListWidgetComponent } from "./components/announcement-admin-list-widget/announcement-admin-list-widget.component";
import { AnnouncementAdminPostWidgetComponent } from "./components/announcement-admin-post-widget/announcement-admin-post-widget.component";
import { AnnouncementAdminDetailsWidgetComponent } from "./components/announcement-admin-post-widget/announcement-admin-details-widget/announcement-admin-details-widget.component";
import { AnnouncementAdminCommentsWidgetComponent } from "./components/announcement-admin-post-widget/announcement-admin-comments-widget/announcement-admin-comments-widget.component";
import { GroupAdminListWidgetComponent } from "./components/group-admin-list-widget/group-admin-list-widget.component";
import { GroupItemWidgetComponent } from "./components/group-item-widget/group-item-widget.component";
import { StudentAdminSearchWidgetComponent } from "./components/student-admin-search-widget/student-admin-search-widget.component";
import { SearchAdminFilterWidgetComponent } from "./components/student-admin-search-widget/search-admin-filter-widget/search-admin-filter-widget.component";
import { StudentSearchAdminSearchBarWidgetComponent } from "./components/student-admin-search-widget/student-search-admin-search-bar-widget/student-search-admin-search-bar-widget.component";
import { SettingsWidgetComponent } from "./components/settings-widget/settings-widget.component";
import { FeedWidgetComponent } from "./components/feed-widget/feed-widget.component";
import { ContactWidgetComponent } from "./components/contact-widget/contact-widget.component";
import { StudentListWidgetComponent } from "./components/student-list-widget/student-list-widget.component";
import { CommunicationAdminListWidgetComponent } from "./components/communication-admin-list-widget/communication-admin-list-widget.component";
import { StudentPinContactInfoWidgetComponent } from "./components/student-pin-profile-contact-info-widget/student-pin-profile-contact-info-widget.component";
import { StudentPinProfileWidgetComponent } from "./components/student-pin-profile-widget/student-pin-profile-widget.component";
import { StudentPinProfileContactWidgetComponent } from "./components/student-pin-profile-contact-widget/student-pin-profile-contact-widget.component";
import { CommunicationInboxMessageWidgetComponent } from "./components/communication-inbox-message-widget/communication-inbox-message-widget.component";
import { CommunicationAlertNotificationWidgetComponent } from "./components/communication-alert-notification-widget/communication-alert-notification-widget.component";
import { CommunicationCalendarEventWidgetComponent } from "./components/communication-calendar-event-widget/communication-calendar-event-widget.component";
import { ResourceCenterDetailsWidgetComponent } from "./components/resource-center-details-widget/resource-center-details-widget.component";
import { StudentAttendanceWidgetComponent } from "./components/student-attendance-widget/student-attendance-widget.component";
import { CommonTableWidgetComponent } from "./components/common-table-widget/common-table-widget.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AccordionWidgetComponent } from "./component/accordion-widget/accordion-widget.component";
import { StudentManagementWidgetComponent } from "./components/student-management-widget/student-management-widget.component";
import { CommentsWidgetComponent } from "./components/comments-widget/comments-widget.component";
import { MailboxContactsDialogComponent } from "./components/mailbox-menu-widget/mailbox-contacts-dialog/mailbox-contacts-dialog.component";
import { ModalWidgetComponent } from "./components/modal-widget/modal-widget.component";
import { PrintBtnWidgetComponent } from "./components/print-btn-widget/print-btn-widget.component";
import { ImageFallbackDirective } from "./components/image-fallback.directive";
import { TranslatorDirective } from "./directives/translator.directive";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { AvatarFallbackWidgetComponent } from "./components/avatar-fallback-widget/avatar-fallback-widget.component";
import { IepReportDownloadConfirmModalComponent } from "./components/iep-report-download-confirm-modal/iep-report-download-confirm-modal.component";
import { PipeModule } from "./../pipe/pipe.module";
import { StudentGraduationReportComponent } from "../routes/shared/student-graduation-report/student-graduation-report.component";
import { ClassDetailsWidgetComponent } from "./components/class-details-widget/class-details-widget.component";
import { ClassManagementWidgetComponent } from "./components/class-management-widget/class-management-widget.component";
import { ClassReinforcementsWidgetComponent } from "./components/class-reinforcements-widget/class-reinforcements-widget.component";
import { HoverActiveDirective } from "./directives/hover-active.directive";
import { ClassEditGroupWidgetComponent } from "./components/class-edit-group-widget/class-edit-group-widget.component";
import { GroupDetailsWidgetComponent } from "./components/group-details-widget/group-details-widget.component";
import { SetQuietHoursComponent } from "./components/set-quiet-hours/set-quiet-hours.component";
import { PbisAudienceWidgetComponent } from "./components/pbis-audience-widget/pbis-audience-widget.component";
import { AudienceListWidgetComponent } from "./components/audience-list-widget/audience-list-widget.component";
import { AddReminderModalComponent } from "./components/add-reminder-modal/add-reminder-modal.component";
import { AddReinforcementModalComponent } from "./components/add-reinforcement-modal/add-reinforcement-modal.component";
import { ImageCropModalComponent } from "./components/settings-widget/image-crop-modal/image-crop-modal.component";
import { NameWidgetComponent } from "./components/name-widget/name-widget.component";
import { ThumbnailPreviewComponent } from "./components/thumbnail-preview/thumbnail-preview.component";
import { StudentEmergencyComponent } from "./components/students-timeline/student-emergency/student-emergency.component";
import { StudentAssignmentsComponent } from "./components/students-timeline/student-assignments/student-assignments.component";
import { StudentBusComponent } from "./components/students-timeline/student-bus/student-bus.component";
import { StudentEslComponent } from "./components/students-timeline/student-esl/student-esl.component";
import { StudentFilesComponent } from "./components/students-timeline/student-files/student-files.component";
import { StudentGateComponent } from "./components/students-timeline/student-gate/student-gate.component";
import { StudentGradesComponent } from "./components/students-timeline/student-grades/student-grades.component";
import { StudentImmunizationComponent } from "./components/students-timeline/student-immunization/student-immunization.component";
import { StudentOnlineFormsComponent } from "./components/students-timeline/student-online-forms/student-online-forms.component";
import { StudentReportcardComponent } from "./components/students-timeline/student-reportcard/student-reportcard.component";
import { StudentSpecialedComponent } from "./components/students-timeline/student-specialed/student-specialed.component";
import { StudentSuspensionComponent } from "./components/students-timeline/student-suspension/student-suspension.component";
import { StudentTestingComponent } from "./components/students-timeline/student-testing/student-testing.component";
import { StudentTransitioningComponent } from "./components/students-timeline/student-transitioning/student-transitioning.component";
import { ClassManagementGroupModalComponent } from "./components/class-management-group-modal/class-management-group-modal.component";
import { ClassCreateGroupModalComponent } from "./components/class-create-group-modal/class-create-group-modal.component";
import { StudentTipsWidgetComponent } from "./components/student-tips-widget/student-tips-widget.component";
import { SelectEventTimeComponent } from "./components/calendar-widget/select-event-time/select-event-time.component";
import { TimelineCommentDialogComponent } from "./components/timeline-comment-dialog/timeline-comment-dialog.component";
import { LazyElementDirective } from "./directives/lazy-element.directive";
import { AutoFocusDirective } from "./directives/auto-focus.directive";
import { BreadCrumbComponent } from "./components/bread-crumb/bread-crumb.component";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { AvatarFallbackNameWidgetComponent } from "./components/avatar-fallback-name-widget/avatar-fallback-name-widget.component";
import { ContainerCardWidgetComponent } from "./components/container-card-widget/container-card-widget.component";
import { ProfileTableWidgetComponent } from "./components/profile-table-widget/profile-table-widget.component";
import { ContactDetailsWidgetComponent } from "./components/contact-details-widget/contact-details-widget.component";
import { ContactNameWidgetComponent } from "./components/contact-name-widget/contact-name-widget.component";
import { TimeagoModule } from "ngx-timeago";
import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas as ProFas } from "@fortawesome/pro-solid-svg-icons";
import { far as ProFar } from "@fortawesome/pro-regular-svg-icons";
import { fal as ProFal } from "@fortawesome/pro-light-svg-icons";

import { ScrollingModule } from "@angular/cdk/scrolling";
import { ReminderCountdownComponent } from "./components/reminder-countdown/reminder-countdown.component";
import { CountdownModule } from "ngx-countdown";
import { ClassEditGroupModalComponent } from "./components/class-edit-group-modal/class-edit-group-modal.component";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { TourModalComponent } from "./components/tour-modal/tour-modal.component";
import { StudentOnlineLibraryComponent } from "./components/students-timeline/student-online-library/student-online-library.component";
import { StudentChecklistComponent } from "./components/student-checklist/student-checklist.component";
import { StudentCcrgComponent } from "./components/student-ccrg/student-ccrg.component";
import { ChecklistComponent } from "./components/student-checklist/checklist/checklist.component";
import { AddAccomplishmentModalComponent } from "./components/add-accomplishment-modal/add-accomplishment-modal.component";
import { CommentsModalComponent } from "./components/comments-modal/comments-modal.component";
import { RemindGradeComponent } from "./components/remind-grade/remind-grade.component";
import { StudentIgpComponent } from "./components/student-igp/student-igp.component";
import { ContactListWidgetComponent } from "./components/contact-list-widget/contact-list-widget.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SkeletonLoaderComponent } from "./components/skeleton-loader/skeleton-loader.component";
import { CalendarEventDetailsComponent } from "./components/calendar-event-details/calendar-event-details.component";
import { CalendarEventQrComponent } from "./components/calendar-event-qr/calendar-event-qr.component";
import { CalendarEventDashboardComponent } from "./components/calendar-event-dashboard/calendar-event-dashboard.component";
import { NgChartsModule } from "ng2-charts";

import { MisisLookupWidgetComponent } from "./components/misis-lookup-widget/misis-lookup-widget.component";
import { PrintEventAttendanceComponent } from "./components/print-event-attendance/print-event-attendance.component";
import { CommentNotificationWidgetComponent } from "./components/comment-notification-widget/comment-notification-widget.component";
import { CustomerContactsComponent } from "./components/customer-contacts/customer-contacts.component";
import { CreateCommunicationComponent } from "./components/create-communication/create-communication.component";
import { ContactProfileComponent } from "../routes/shared/contacts-route/contact-profile/contact-profile.component";
import { RemoveStudentModalComponent } from "./components/remove-student-modal/remove-student-modal.component";
import { StudentDetailsFallbackWidgetComponent } from "./components/student-details-fallback-widget/student-details-fallback-widget.component";
import { PrintPinModalComponent } from "./components/print-pin-modal/print-pin-modal.component";
import { StudentContactCardComponent } from './components/student-contact-card/student-contact-card.component';
import { CreateCommunicationGroupsComponent } from "./components/create-communication-groups/create-communication-groups.component";
import { NotificationsMenuComponent } from '../shared/components/notifications-menu/notifications-menu.component';
import { SurveyManagerPageComponent } from "../routes/shared/survey-manager-route/survey-manager-page/survey-manager-page.component";
import { PhonePipe } from "src/app/pipe/phone.pipe";
import { IgpWidgetComponent } from './components/igp-widget/igp-widget.component';
import { PtgWidgetComponent } from './components/ptg-widget/ptg-widget.component';
import { StudentContactCardPlaceholderComponent } from './components/student-contact-card-placeholder/student-contact-card-placeholder.component';
import { StudentIapButtonComponent } from './components/student-iap-button/student-iap-button.component';
import { StudentBuildingSkillsComponent } from './components/students-timeline/student-building-skills/student-building-skills.component'
import { SideFabButtonComponent } from './components/side-fab-button/side-fab-button.component'
import { LanguageModalComponent } from './components/language-modal/language-modal.component'
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { StudentHealthAndWellnessComponent } from './components/students-timeline/student-health-and-wellness/student-health-and-wellness.component';
import { StudentHealthConsentComponent } from "./components/students-timeline/student-health-consent/student-health-consent.component";
import { RelativePlacementIconComponent } from './components/students-timeline/student-testing/relative-placement-icon/relative-placement-icon.component';
import { ParentSurveyComponent } from './components/parent-survey/parent-survey.component';
import { ParentSurveyListComponent } from './components/parent-survey-list/parent-survey-list.component';
import { QrCodeModule} from 'ng-qrcode'
import { BuspassComponent } from "../routes/student/buspass-route/buspass/buspass.component";
import { StudentTestingDownloadComponent } from './components/students-timeline/student-testing-download/student-testing-download.component';
import { StudentTutoringServicesComponent } from './components/students-timeline/student-tutoring-services/student-tutoring-services.component';
import { StudentTestingDownloadAllComponent } from './components/students-timeline/student-testing-download-all/student-testing-download-all.component';
import { ChatbotWidgetComponent } from "./components/chatbot-widget/chatbot-widget.component";


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);
// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        TranslateModule,
        RouterModule,
        FontAwesomeModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        NgxSelectModule,
        FilePickerModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        TourMatMenuModule.forRoot(),
        // AgmCoreModule.forRoot({
        //   apiKey: localStorage.getItem("app_gkey"),
        // }),
        ImageCropperModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTableModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatStepperModule,
        PipeModule,
        ScrollingModule,
        CountdownModule,
        NgxSkeletonLoaderModule,
        NgChartsModule ,
        TimeagoModule.forChild(),
        NgxDocViewerModule,
        // NgxMaskModule.forRoot(),
        QrCodeModule
    ],
    providers: [ColorsService],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        // custom components
        ClockWidgetComponent,
        ChatAreaComponent,
        CommentNotificationWidgetComponent,
        FilePreviewModalComponent,
        ExpectationIconWidgetComponent,
        NotificationWidgetComponent,
        StudentDetailsWidgetComponent,
        PopularWidgetComponent,
        AnnouncementWidgetComponent,
        NewsWidgetComponent,
        MailboxComposeWidgetComponent,
        MailboxMenuWidgetComponent,
        MailboxFolderWidgetComponent,
        MailboxMenuWidgetComponent,
        ImportantAlertsWidgetComponent,
        TipsDayWidgetComponent,
        StudentPinProfileWidgetComponent,
        TimelineWidgetComponent,
        StudentPinProfileContactWidgetComponent,
        StudentSmallProfileWidgetComponent,
        StudentTeachersWidgetComponent,
        StudentFriendsWidgetComponent,
        StudentHeaderWidgetComponent,
        FaqSectionWidgetComponent,
        AnnouncementAdminListWidgetComponent,
        StudentPinContactInfoWidgetComponent,
        AnnouncementAdminPostWidgetComponent,
        AnnouncementAdminDetailsWidgetComponent,
        AnnouncementAdminCommentsWidgetComponent,
        GroupAdminListWidgetComponent,
        GroupItemWidgetComponent,
        StudentAdminSearchWidgetComponent,
        SearchAdminFilterWidgetComponent,
        StudentListWidgetComponent,
        StudentSearchAdminSearchBarWidgetComponent,
        CalendarWidgetComponent,
        SettingsWidgetComponent,
        ResourceCenterWidgetComponent,
        FeedWidgetComponent,
        ContactWidgetComponent,
        EventsWidgetComponent,
        CommunicationAdminListWidgetComponent,
        CommunicationInboxMessageWidgetComponent,
        CommunicationAlertNotificationWidgetComponent,
        CommunicationCalendarEventWidgetComponent,
        ResourceCenterDetailsWidgetComponent,
        StudentAttendanceWidgetComponent,
        CommonTableWidgetComponent,
        AccordionWidgetComponent,
        StudentManagementWidgetComponent,
        CommentsWidgetComponent,
        CountdownWidgetComponent,
        MailboxContactsDialogComponent,
        ModalWidgetComponent,
        PrintBtnWidgetComponent,
        ImageFallbackDirective,
        TranslatorDirective,
        AvatarFallbackWidgetComponent,
        IepReportDownloadConfirmModalComponent,
        StudentGraduationReportComponent,
        ClassDetailsWidgetComponent,
        ClassManagementWidgetComponent,
        ClassReinforcementsWidgetComponent,
        HoverActiveDirective,
        ClassEditGroupWidgetComponent,
        GroupDetailsWidgetComponent,
        SetQuietHoursComponent,
        PbisAudienceWidgetComponent,
        AudienceListWidgetComponent,
        AddReminderModalComponent,
        AddReinforcementModalComponent,
        ImageCropModalComponent,
        AvatarFallbackWidgetComponent,
        NameWidgetComponent,
        StudentCertificatesComponent,
        StudentExpectationsComponent,
        ThumbnailPreviewComponent,
        NewExpectationModalComponent,
        StudentSurveysComponent,
        StudentAnswerComponent,
        ViewResultsComponent,
        StudentProfileComponent,
        StudentTimelineComponent,
        StudentEmergencyComponent,
        StudentEslComponent,
        StudentGradesComponent,
        StudentAssignmentsComponent,
        StudentImmunizationComponent,
        StudentOnlineFormsComponent,
        StudentReportcardComponent,
        StudentSpecialedComponent,
        StudentSuspensionComponent,
        StudentTestingComponent,
        StudentTransitioningComponent,
        StudentGateComponent,
        StudentBusComponent,
        StudentFilesComponent,
        StudentManagementComponent,
        ClassManagementGroupModalComponent,
        ClassCreateGroupModalComponent,
        StudentTipsWidgetComponent,
        SelectEventTimeComponent,
        StudentAttendanceComponent,
        TimelineCommentDialogComponent,
        LazyElementDirective,
        AutoFocusDirective,
        BreadCrumbComponent,
        AvatarFallbackNameWidgetComponent,
        ContainerCardWidgetComponent,
        ProfileTableWidgetComponent,
        ContactDetailsWidgetComponent,
        ContactNameWidgetComponent,
        ReminderCountdownComponent,
        ClassEditGroupModalComponent,
        TourModalComponent,
        StudentOnlineLibraryComponent,
        StudentOnlineLibraryComponent,
        StudentChecklistComponent,
        StudentCcrgComponent,
        ChecklistComponent,
        AddAccomplishmentModalComponent,
        CommentsModalComponent,
        RemindGradeComponent,
        StudentIgpComponent,
        ContactListWidgetComponent,
        SkeletonLoaderComponent,
        CalendarEventDetailsComponent,
        CalendarEventQrComponent,
        CalendarEventDashboardComponent,
        MisisLookupWidgetComponent,
        PrintEventAttendanceComponent,
        CustomerContactsComponent,
        CreateCommunicationComponent,
        ContactProfileComponent,
        RemoveStudentModalComponent,
        StudentDetailsFallbackWidgetComponent,
        StudentContactCardComponent,
        CreateCommunicationGroupsComponent,
        NotificationsMenuComponent,
        SurveyManagerPageComponent,
        IgpWidgetComponent,
        PtgWidgetComponent,
        StudentContactCardPlaceholderComponent,
        StudentIapButtonComponent,
        StudentBuildingSkillsComponent,
        SideFabButtonComponent,
        LanguageModalComponent,
        StudentHealthAndWellnessComponent,
        StudentHealthConsentComponent,
        RelativePlacementIconComponent,
        BuspassComponent,
        StudentTestingDownloadComponent,
        ParentSurveyComponent,
        ParentSurveyListComponent,
        StudentTutoringServicesComponent,
        StudentTestingDownloadAllComponent,
        ChatbotWidgetComponent
    ],
    exports: [
      TimeagoModule,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        CommonModule,
        FormsModule,
        GroupItemWidgetComponent,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        NgxSelectModule,
        CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToastrModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        HoverActiveDirective,
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTableModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatStepperModule,
        PipeModule,
        // custom components
        ClockWidgetComponent,
        FilePreviewModalComponent,
        ExpectationIconWidgetComponent,
        NotificationWidgetComponent,
        StudentDetailsWidgetComponent,
        PopularWidgetComponent,
        StudentPinContactInfoWidgetComponent,
        AnnouncementWidgetComponent,
        NewsWidgetComponent,
        MailboxComposeWidgetComponent,
        MailboxMenuWidgetComponent,
        CommentNotificationWidgetComponent,
        MailboxFolderWidgetComponent,
        ImportantAlertsWidgetComponent,
        StudentListWidgetComponent,
        TipsDayWidgetComponent,
        TimelineWidgetComponent,
        StudentSmallProfileWidgetComponent,
        StudentTeachersWidgetComponent,
        StudentFriendsWidgetComponent,
        StudentPinProfileContactWidgetComponent,
        StudentHeaderWidgetComponent,
        FaqSectionWidgetComponent,
        CalendarWidgetComponent,
        ResourceCenterWidgetComponent,
        SettingsWidgetComponent,
        FeedWidgetComponent,
        AnnouncementAdminListWidgetComponent,
        StudentPinProfileWidgetComponent,
        AnnouncementAdminPostWidgetComponent,
        AnnouncementWidgetComponent,
        EventsWidgetComponent,
        ContactWidgetComponent,
        StudentAdminSearchWidgetComponent,
        CommunicationAdminListWidgetComponent,
        CommunicationInboxMessageWidgetComponent,
        CommunicationAlertNotificationWidgetComponent,
        CommunicationCalendarEventWidgetComponent,
        ResourceCenterDetailsWidgetComponent,
        StudentAttendanceWidgetComponent,
        CommonTableWidgetComponent,
        AccordionWidgetComponent,
        StudentManagementWidgetComponent,
        CountdownWidgetComponent,
        ModalWidgetComponent,
        PrintBtnWidgetComponent,
        ImageFallbackDirective,
        TranslatorDirective,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        IepReportDownloadConfirmModalComponent,
        StudentGraduationReportComponent,
        ClassDetailsWidgetComponent,
        ClassManagementWidgetComponent,
        ClassReinforcementsWidgetComponent,
        ClassEditGroupWidgetComponent,
        GroupDetailsWidgetComponent,
        ClassEditGroupWidgetComponent,
        PbisAudienceWidgetComponent,
        AddReminderModalComponent,
        AvatarFallbackWidgetComponent,
        NameWidgetComponent,
        StudentExpectationsComponent,
        StudentCertificatesComponent,
        ThumbnailPreviewComponent,
        NewExpectationModalComponent,
        StudentSurveysComponent,
        StudentAnswerComponent,
        ViewResultsComponent,
        StudentProfileComponent,
        StudentTimelineComponent,
        StudentEmergencyComponent,
        StudentEslComponent,
        StudentGradesComponent,
        StudentAssignmentsComponent,
        StudentImmunizationComponent,
        StudentOnlineFormsComponent,
        StudentReportcardComponent,
        StudentSpecialedComponent,
        StudentSuspensionComponent,
        StudentTestingComponent,
        StudentTransitioningComponent,
        StudentGateComponent,
        StudentBusComponent,
        StudentFilesComponent,
        StudentManagementComponent,
        StudentTipsWidgetComponent,
        SelectEventTimeComponent,
        StudentAttendanceComponent,
        LazyElementDirective,
        BreadCrumbComponent,
        ContainerCardWidgetComponent,
        ProfileTableWidgetComponent,
        ContactNameWidgetComponent,
        ReminderCountdownComponent,
        ClassEditGroupModalComponent,
        StudentOnlineLibraryComponent,
        StudentChecklistComponent,
        StudentCcrgComponent,
        ChecklistComponent,
        AddAccomplishmentModalComponent,
        CommentsModalComponent,
        RemindGradeComponent,
        ContactListWidgetComponent,
        SkeletonLoaderComponent,
        StudentDetailsFallbackWidgetComponent,
        NameWidgetComponent,
        CreateCommunicationGroupsComponent,
        StudentIapButtonComponent,
        StudentBuildingSkillsComponent,
        NotificationsMenuComponent,
        SideFabButtonComponent,
        BuspassComponent,
        StudentTutoringServicesComponent,
        ChatbotWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far, ProFas, ProFal, ProFar);
  }
}
