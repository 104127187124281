import { PrintService } from './../../services/print.service';
import { Component, OnInit } from '@angular/core';

let externalWindow = window;
@Component({
  selector: 'app-print-btn-widget',
  template: '<span (click)="print()" v3-translate>Print</span>'
})
export class PrintBtnWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
  print(){
    externalWindow.print();
  }
}
