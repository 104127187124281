import { environment } from "src/environments/environment";
import { Component, OnInit } from "@angular/core";
import { AppConfigService } from "../services/app-config.service";
import { AnalyticsService } from "../shared/services/analytics.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  env = environment;
  showFeedback = false;
  showChat = false;
  constructor(
    private appConfig: AppConfigService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  onClose(event) {
    this.showFeedback = false;
    this.showChat = false;
  }

  action(value) {
    if (value === "forum") {
      this.analytics.sendEvent("User Click", "Help Desk");
      this.showChat = true;
      // if (
      //   this.appConfig.configuration.environment.endPoints.authenticationServices.includes(
      //     "servicesdev"
      //   )
      // ) {
      //   window.open(
      //     "https://lausddev.service-now.com/$sn-va-web-client-app.do?sysparm_nostack=true&sysparm_stack=no&sysparm_portal=lausd&sysparm_page=customer_home&sysparm_language=en&sysparm_liveagent_application=csm&sysparm_live_agent_only=false&sysparm_branding_key=lausd_customer_service"
      //   );
      // } else {
      //   window.open(
      //     "https://lausd.service-now.com/$sn-va-web-client-app.do?sysparm_nostack=true&sysparm_stack=no&sysparm_portal=lausd&sysparm_page=customer_home&sysparm_language=en&sysparm_liveagent_application=csm&sysparm_live_agent_only=false&sysparm_branding_key=lausd_customer_service"
      //   );
      // }
    } else {
      this.showFeedback = true;
    }
  }
}
