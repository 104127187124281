import { AfterViewInit, ViewChild, Component, OnInit, Renderer2, ElementRef, Input  } from '@angular/core';
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: 'app-chatbot-widget',
  templateUrl: './chatbot-widget.component.html',
  styleUrls: ['./chatbot-widget.component.scss']
})
export class ChatbotWidgetComponent implements OnInit, AfterViewInit {

  env = environment;
  @ViewChild('chatbotWidget', { static: false }) chatbot!: ElementRef;
  @Input() appID: number;

  userType: string = 'Parent';    
  language: string = 'en';
  chatbotScriptUrl: string = "https://cdn.lausd.net/chatbot/chatbot.js";

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private oAuthService: OAuthService) { }

  ngOnInit(): void {

    console.log('Chatbot oninit');      
    this.loadChatScript();

  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.initializeChatbot();
    // },0 );
    
  }


  loadChatScript() {

    if (this.env.enableChatbot == false) {
      return;
    }

    if (!document.querySelector(`script[src="${this.chatbotScriptUrl}"]`)) {
      const script = this.renderer.createElement('script');
      script.src = this.chatbotScriptUrl;      
      script.onload = () => {
        this.initializeChatbot(); // Ensure script is loaded before calling methods
      };
      document.body.appendChild(script);
    }
    else {
      this.initializeChatbot();
    }
  }

  

  initializeChatbot() {

    const chatbotElement = this.chatbot.nativeElement as any;
    
    if (chatbotElement) {
      chatbotElement.setAuthToken(this.oAuthService.authorizationHeader());
      chatbotElement.setAppID(this.appID);
      chatbotElement.setUserType(this.userType);      
      chatbotElement.setDeviceType(window.navigator.userAgent);

      console.log('appid ' + this.appID);      
      console.log('Chatbot initialized');      
    } else {
      console.error('Chatbot widget error');
    }
  }
}
